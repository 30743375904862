@import "@/styles/common.less";
@import "@/styles/override-antd.less";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  user-select: none;
  font-size: @font-size-base;
  color: @color-base;
}

/* chrome */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 100px white inset !important;
  box-shadow: 0 0 0px 100px white inset !important;
}
/* firefox */
input {
  filter: none !important;
}
a {
  svg{
    fill: @color-system-base;
  }
}

