@import "./variables.less";

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.empty-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  color: @color-tip;
  line-height: 1;
  .svg_icon {
    margin-bottom: 20px;
  }
  .title {
    color: @color-sub-base;
  }
  .sub-title {
    margin-top: 8px;
    color: @color-tip;
  }
}
.base-text-over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.base-text-over_wrap {
    white-space: break-spaces;
  }
}

._custom_radio_{
  .ant-radio-checked{
    .ant-radio-inner {
      border: 4px solid #006DFA !important;
    }
    .ant-radio-inner::after {
      background-color: #fff;
    }
  }
  .ant-radio-inner {
    border-color: #ddd;
  }
}
._custom_radio_ {

}
.ant-radio-wrapper {
  color: #333;
}

.marginTop16 {
  margin-top: 16px;
}

.template-red{
  color: #FF4A4A!important;
  background: #FFEDED!important;
}
.template-green{
  color: #04924E!important;
  background: #DBF7EA!important;
}
.template-blue, .template-default{
  color: #006DFA!important;
  background: #E6F0FF!important;
}
.template-purple{
  color: #5644DD!important;
  background: #DDDAF8!important;
}
.template-orange, .template-yellow{
  color: #FF8829!important;
  background: #FFF3EA!important;
}
