@import "@/styles/variables.less";

.codebox-container {
  display: block;
  text-align: left;
  margin: 16px 0 6px;
}

.codebox-container .codebox-field-wrap {
  display: inline-block;
  margin: 4px;
  width: 46px;
  height: 46px;
}


.codebox-container .codebox-field-wrap input {
  width: inherit;
  height: inherit;
  line-height: inherit;
  font-size: @font-size-22;
  font-weight: 500;
  text-align: center;
  vertical-align: baseline;
  outline: none;
  color: #494949;
  border: 1px solid #ddd;
  border-radius: 6px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px #fff inset !important;
    background-color: #fff !important;
    background-clip: content-box !important;
  }
}

