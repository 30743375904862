@import "./variables.less";

.ant-layout {
  overflow: hidden;
  position: relative;

  .ant-layout-header {
    line-height: 1;
  }
}

.ant-btn-primary {
  font-size: @font-size-base;
  background: @btn-primary-bg;
  border-radius: @border-radius-base;

  &.ant-btn-loading::before {
    opacity: 0 !important;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background: #006DFA;
  opacity: 0.2;
  color: #fff;
}

.ant-btn-default {
  font-size: @font-size-base;
  border-radius: @border-radius-base;
  color: @color-base;
  border-color: #ddd;

  &.ant-btn-loading::before {
    opacity: 0 !important;
  }

  &:hover {
    background-color: #f2f2f2;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #ddd;
    box-shadow: none;
    outline: none;
    color: #333;
  }
}

.ant-btn {
  outline: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &::after {
    border: 0 none;
    opacity: 0;
    outline: none;
    animation: 0 ease 0 1 normal;
  }
}

.ant-tooltip-inner,
.ant-avatar-square {
  border-radius: @border-radius-base;
}

.ant-message {
  z-index: 99999999;
}

.ant-message-notice-content {
  min-width: 369px;
  max-width: 500px;
  height: 52px;
  margin: 0 auto;
  padding: 0;
  border-radius: @border-radius-base;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  .ant-message-custom-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;

    .anticon {
      font-size: @font-size-18;
      margin-top: -2px;
    }

    .anticon+span {
      font-weight: 400;
      color: @color-base;
      line-height: @font-size-18;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.ant-message-warning {
      .anticon {
        color: @color-warning;
      }
    }

    &.ant-message-success {
      .anticon {
        color: @color-success;
      }
    }
  }
}

.ant-select-dropdown {
  padding: 4px 2px;

  .ant-select-item {
    border-radius: @border-radius-sm;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: @system-base-color;
    background: @pin-active-bg;
  }

  .rc-virtual-list-scrollbar {
    display: none;
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-radius: 50%;
    border-color: #ddd;

    &:hover,
    &:focus,
    &:active {
      border-radius: 50%;
      border-color: @system-base-color;
    }
  }

  .ant-checkbox-checked {
    &::after {
      border-color: @system-base-color;
      border-radius: 50%;
    }

    .ant-checkbox-inner {
      border-color: @system-base-color;
      background-color: @system-base-color;
    }
  }
}

.ant-modal-header {
  padding: 20px;
  border-bottom: none;
  color: @color-base;

  .ant-modal-close {
    .anticon {
      color: #999;
    }
  }
}

.ant-input-affix-wrapper {
  background: transparent;
  border-color: #ddd;
}

.ant-input {
  background: transparent;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper-focused:not(.ant-input-affix-wrapper-disabled) {
  border-color: @color-system-base !important;
  box-shadow: none;
}

.ant-modal-confirm-confirm {
  .ant-modal-body {
    padding-top: 20px;
  }
}

.ant-tooltip {
  font-size: @font-size-12;

  &.ant-tooltip-placement-bottom,
  &.ant-tooltip-placement-bottomLeft,
  &.ant-tooltip-placement-bottomRight {
    margin-top: -2px !important;
    .ant-tooltip-arrow {
      top: 1px !important;
    }
  }
}

.ant-dropdown .ant-dropdown-menu {
  padding: 6px;

  .ant-dropdown-menu-item {
    border-radius: @border-radius-base;
    font-size: 12px;

    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;

      .svg_icon {
        margin-right: 8px;
      }

      .anticon {
        margin-right: 8px;
      }
    }
  }

  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected {
    color: #006DFA;
    background-color: #fff;
  }

  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(51, 51, 51, 0.4);
  }
}

.ant-popover.popover-menu-container {
  padding-top: 0 !important;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    overflow: hidden;

    .ant-popover-inner-content {
      padding: 0;
    }
  }
}

h1 {
  font-size: 28px;
  margin-bottom: 0;
}

h2 {
  font-size: 25px;
  margin-bottom: 0;
}

h3 {
  font-size: 22px;
  margin-bottom: 0;
}

h4 {
  font-size: 19px;
  margin-bottom: 0;
}

h5 {
  font-size: 15px;
  margin-bottom: 0;
}

/*滚动条 start*/
&:not(.origin-scrollbar)::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: #F7F9FA;
  border-radius: 3px;
}

/*定义滚动条轨道 内阴影+圆角*/
&:not(.origin-scrollbar)::-webkit-scrollbar-track {
  background: #F7F9FA;
}

/*定义滑块 内阴影+圆角*/
&:not(.origin-scrollbar)::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #ddd;
}

&:not(.origin-scrollbar)::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  background-color: #ddd;
}

img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

a {
  color: @system-base-color;

  &:hover,
  &:active {
    color: @system-base-color;
  }
}

.ant-badge-multiple-words {
  padding: 0 4px;
}

.ant-message-notice.notification,
.ant-message-notice.toast {
  display: flex;
  justify-content: flex-end;

  .ant-message-notice-content {
    float: right;
    margin-right: 30px;
    padding: 10px 20px;
    width: max-content;

    .ant-message-custom-content {
      padding: 0;
    }
  }
}

.ant-switch.ant-switch-checked {
  background-color: @system-base-color;
}

.bf-link-editor .switch-group {
  display: none;
}

.bf-link-editor .button-remove-link {
  display: none;
}

.ant-checkbox-inner {
  border-color: @border-color;
  width: 18px;
  height: 18px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: @system-base-color;
  border-color: @system-base-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: @system-base-color;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: @border-color !important;
  background: @background-base;
}
