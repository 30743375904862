.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .tip {
    color: #666;
  }
  .ant-btn {
    width: 150px;
  }
}
.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .img {
    width: 200px;
    height: 200px;
  }
  .label {
    font-size: 14px;
    line-height: 14px;
    color: #666;
  }
}

