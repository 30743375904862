@import '@/styles/variables.less';

.login_container {
  min-width: 380px;
  height: 100vh;

  .login_wapper {
    display: flex;
    height: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    .left_container,
    .login_form {
      height: 500px;
    }
    .left_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 266px;
      margin-right: 122px;

      img {
        width: 266px;
        height: 188px;
      }

      .title {
        padding-top: 60px;
        text-align: center;
        font-size: @font-size-18;
        font-weight: 400;
        color: @color-sub-base;
      }

    }

    .login_form {
      position: relative;
      padding: 80px 30px 0;
      width: 380px;
      border-radius: 10px;
      box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.15);

      .back {
        position: absolute;
        top: 30px;
        left: 30px;
        padding-left: 20px;
        font-size: @font-size-16;
        color: @color-tip;
        background: url("~@/assets/images/login/back.png") left 3px no-repeat;
        background-size: 16px 16px;
        cursor: pointer;
      }

      .form_title {
        font-size: @font-size-22;
        font-weight: 600;
        color: @color-base;
        .sub_title {
          color: #428be5;
        }
      }

      .login-input {
        .ant-input-affix-wrapper {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }

      .login-input-select-active {
        .ant-input-group-addon {
          border-color: #006DFA !important;
        }

        .ant-input-affix-wrapper {
          border-left-color: #006DFA !important;
        }
      }

      .sub_tip {
        margin-top: 10px;
        font-size: @font-size-base;
        color: @color-sub-base;
        span {
          font-weight: 500;
          color: @color-base;
        }
      }

      .verify-status {
        font-size: @font-size-base;
        color: @color-tip;
        .reset-code {
          color: @color-system-base;
          cursor: pointer;
        }
        .verify-error {
          float: right;
          color: @color-warning;
        }
      }

      .loading_spin {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
      }

      .name_input {
        display: flex;
        flex-direction: column;
        margin: 60px 0 20px;
        .ant-input {
          height: 40px;
          font-size: @font-size-16;
          border: 0;
          border-radius: @border-radius-base;
        }
        .name-error {
          margin: 0;
          padding: 10px 10px 0;
          color: @color-warning;
        }
      }
      .save-name-btn {
        font-size: @font-size-16;
        width: 100%;
        height: 40px;
      }

      .login-type {
        position: absolute;
        top: 10px;
        right: 10px;
        .icon {
          width: 60px;
          height: 60px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          cursor: pointer;
          &.phone {
            background-image: url("~@/assets/images/login/login-type-phone.png");
          }
          &.scan {
            background-image: url("~@/assets/images/login/login-type-scan.png");
          }
        }
      }

      .ant-form {
        margin-top: 60px;
        // height: 180px;
        .ant-form-item {
          margin-bottom: 20px;
        }
        .ant-input-affix-wrapper {
          height: 40px;
          padding-top: 0;
          padding-bottom: 0;
          border-radius: @border-radius-base;
          .ant-input,
          .ant-input-suffix {
            font-size: @font-size-16;
          }
        }
        .ant-btn-primary {
          width: 100%;
          height: 40px;
        }
      }

      .scan-inner {
        .form_title {
          text-align: center;
          font-size: 24px;
          padding-bottom: 10px;
        }

        .scan-tip {
          text-align: center;
          font-size: 16px;
          color: #999;
          padding-bottom: 16px;
        }

        .scan {
          position: relative;
          width: 180px;
          height: 180px;
          margin: 0 auto;
          .scan-reload {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: rgba(51, 51, 51, 0.65);
            cursor: pointer;
            .loading-circle {
              .loading-circle-inner {
                width: 46px;
                height: 46px;
                margin-bottom: 10px;
              }
              .loading-label {
                color: #fff;
              }
            }
          }
        }

      }

      .userinfo-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .ant-avatar {
          margin: 60px auto 10px;
        }
        .username {
          font-size: @font-size-18;
          color: @color-sub-base;
          font-weight: 600;
        }
        .mobile-confirm {
          margin-top: 100px;
          font-size: @font-size-16;
          color: @color-tip;
        }
      }

      .select-team-wrap {
        .team-list {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 31px;
          margin-left: -30px;
          width: calc(100% + 60px);
          height: 300px;
          scrollbar-gutter: stable both-edges;
          overflow-y: auto;
          .team-item {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px;
            width: 320px;
            height: 68px;
            border-radius: 6px;
            border: 1px solid @border-color;
            cursor: pointer;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            &:hover {
              background-color: @background-base-F9;
            }
            .team-info {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              overflow: hidden;
              .team-name {
                font-weight: 500;
                font-size: @font-size-16;
              }
              .nickname {
                color: @color-sub-base;
              }
            }
            .loading-circle-inner  {
              margin-right: 0;
              width: 20px;
              height: 20px;
              background-image: url("~@/assets/images/login/loading.png");
            }
          }
        }
      }

      .protocol-wrap {
        font-size: @font-size-base;
        .protocol-tip {
          display: flex;
          align-items: center;
          gap: 6px;
          padding-left: 30px;
          height: @font-size-base;
          color: @color-sub-base;
          & + .protocol-tip {
            margin-top: 10px;
          }
          .name {
            color: @system-base-color;
          }
          .ant-tooltip-arrow {
            left: 6px !important;
          }
        }
        .primary-content {
          display: flex;
          align-items: center;
          gap: 6px;
          padding-left: 30px;
          margin-top: 24px;
          color: @color-tip;
          .primary {
            color: @color-system-base;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.login-input-select {
  background-color: #fff;
  width: 78px;
  .ant-select-selector {
    padding: 0 9px !important;
  }

  .ant-select-selection-item {
    color: #666 !important;
    font-size: 16px;
  }
}

.login-input-select-popup {
  padding: 8px !important;

  .ant-select-item {
    padding: 6px;
  }

  .ant-select-item-group {
    padding-top: 0;
    min-height: 26px;
    font-size: 14px;
    line-height: 22px;
    color: #999;
  }

  .ant-select-item-option-grouped {
    font-weight: 400 !important;
  }

  .login-input-select-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .login-input-select-label-content {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .login-input-select-label-code {
    width: 40px;
    text-align: left;
  }

  .ant-select-item-option-selected {
    background-color: #fff !important;
  }
}


@media screen and (max-width: 768px) {
  .login_container {
    .left_container {
      display: none !important;
    }
    .login_form {
      width: 360px !important;
      padding: 80px 20px 0 !important;
    }
  }
}

