.ellipsis-inner {
  position: relative;
  overflow: hidden;
  .ellipsis-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ellipsis-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    white-space: nowrap;
  }
}

.ellipsis-tootip {
  max-width: 400px !important;
}

