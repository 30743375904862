.avatar-inner {
  position: relative;
  display: flex;
  .work-area {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #00C868;
    cursor: pointer;
  }
}

