@import "@/styles/variables.less";

.svg-hover-inner {
  flex-shrink: 0;
  padding: 3px;
  font-size: 0;
  border-radius: @border-radius-sm;
  cursor: pointer;

  &:hover {
    background: @opt-hover-bg;
  }
}

svg {
  display: inline-block;

  &.icon_setting,
  &.icon_create_group,
  &.icon_search_msg,
  &.icon_cve_workbench,
  &.icon_video_conference,
  &.icon_email,
  &.icon_cve_chat {
    width: @tool-left-svg-size;
    height: @tool-left-svg-size;
  }

  &.icon_organization,
  &.icon_mygroup {
    width: @avatar-middle-size;
    height: @avatar-middle-size;
  }

  &.icon_locdown,
  &.icon_read,
  &.icon_memo_del_hover,
  &.icon_memo_del,
  &.icon_memo_more,
  &.icon_pin_msg,
  &.icon_pin_msg_hover,
  &.icon_remove_pin_msg,
  &.icon_remove_pin,
  &.icon_remove_pin_hover,
  &.icon_add_notice,
  &.icon_fail_msg {
    width: 14px;
    height: 14px;
  }

  &.icon_sh_msg,
  &.icon_merge_msg,
  &.icon_del_msg,
  &.icon_memo_msg,
  &.icon_active_memo_msg,
  &.icon_active_sh_msg,
  &.icon_active_merge_msg,
  &.icon_del,
  &.icon_download,
  &.icon_refresh,
  &.icon_close,
  &.icon_folder,
  &.icon_active_del_msg {
    width: 18px;
    height: 18px;
  }

  &.icon_gold,
  &.icon_mind_update,
  &.icon_drive,
  &.icon_copylink,
  &.icon_input_at,
  &.icon_input_emoji,
  &.icon_input_screenshot,
  &.icon_input_send_msg,
  &.icon_memo,
  &.icon_notification_close,
  &.icon_active_input_send_msg {
    width: 16px;
    height: 16px;
  }

  &.icon_at_all {
    width: 24px;
    height: 24px;
  }

  &.icon_video_upload,
  &.icon_video_play,
  &.icon_video_download,
  &.icon_file_audio,
  &.icon_file_video,
  &.icon_file_excel,
  &.icon_file_powerpoint,
  &.icon_file_pdf,
  &.icon_file_code,
  &.icon_file_word,
  &.icon_file_patch,
  &.icon_file_other,
  &.icon_file_image,
  &.icon_file_dir,
  &.icon_file_wiz,
  &.icon_file_gif,
  &.icon_file_svg,
  &.icon_file_txt,
  &.icon_file_zip,
  &.icon_file_unknown,
  &.icon_file_apk {
    width: 38px;
    height: 38px;
    flex-shrink: 0;
  }

  &.icon_mind_logo {
    width: 48px;
    height: 48px;
  }
}

