@import "@/styles/variables.less";

.loading-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  .loading-circle-inner {
    margin-right: 6px;
    width: 16px;
    height: 16px;
    background: url("~@/assets/images/common/loading-circle.png") no-repeat;
    background-size: 100%;
    &.animation {
      animation: loadRotate 1.2s infinite linear;
    }
  }
  .loading-label {
    font-size: 14px;
    line-height: 16px;
    color: @color-tip;
  }
}

@keyframes loadRotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
